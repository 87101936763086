import { permissions } from '@mpk/shared/domain';

/**
 * Permission for the article search
 */
enum ArticleSearchPermissionsEnum {
  None = 0,
  /**
   * Article search can be used
   */
  UseArticleSearch = 1,

  /**
   * Datasources to global articles can be viewed in the article search
   */
  ViewDataSources = 2,

  /**
   * Unlocks the data provision for the export api in the article search
   */
  UseExportApi = 4 | UseArticleSearch,

  /**
   * Use export entries of all users of the own organization.
   */
  UseExportHistoryOfOwnOrganization = 8 | UseArticleSearch,
}

export const ArticleSearchPermissions = permissions(ArticleSearchPermissionsEnum, {
  featureId: 'ArticleSearch',
});
